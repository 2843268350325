
<script>
import { Bar } from 'vue-chartjs'
import format from "date-fns/format";

export default {
  extends: Bar,
  mounted () {
    this.drawChartByCount();
    this.renderChart(this.chartDataByCount, this.optionsByCount);
  },
  computed: {
    datesList() {
      return this.$store.getters["reports/datesList"]
    }
  },
  watch: {
    datesList: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drawChartByCount();
        this.renderChart(this.chartDataByCount, this.optionsByCount);
      }
    }
  },
  methods: {
    drawChartByCount() {
      this.chartDataByCount = {
        labels: this.datesList.map(f => format(new Date(f.finished_at), "dd-MM-yy")),
        datasets: [
          {
            label: 'Без. нал.',
            data: this.datesList.map(s => s.cashless),
            backgroundColor: 'rgb(1,230,50)',
            borderColor: 'rgb(150,199,9)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgb(1,180,9)',
          },
          {
            label: 'Всего',
            data: this.datesList.map(s => s.cash),
            backgroundColor: 'rgb(3,88,182)',
            borderColor: 'rgb(50,126,222)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgb(1,23,182)',
          },
        ]
      }
      this.optionsByCount = {
        animation: {
          easing: 'easeOutElastic'
        },
        title: {
          display: true,
          text: "По количеству заказов"
        },
        tooltips: {
          displayColors: true,
          backgroundColor: 'rgb(100,50,0)',
          titleFontColor: 'rgb(255,255,255)',
          bodyFontColor: 'rgb(255,255,255)',
          titleAlign: 'center',
          callbacks: {
            label: function (item) {
              if (item && item.yLabel && item.datasetIndex === 0) {
                let count = (item.yLabel).toLocaleString();
                return "Безнал. кол. : " + count
              }
              else if (item && item.yLabel && item.datasetIndex === 1) {
                let count = (item.yLabel).toLocaleString();
                return "Нал. кол. : " + count
              }
            },
            footer: function (item) {
              if (item[0] && item[1] && item[0].yLabel && item[1].yLabel) {
                let total = item[0].yLabel + item[1].yLabel;
                total = total.toLocaleString();
                return "Всего : " + total + " заказ(ов)"
              }
            }
          },
          mode: 'index',
        },
        scales: {
          xAxes: [{
            stacked: true,
            grid: {
              offset: false
            },
            gridLines: {
              display: false
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: "Количество заказов"
            },
            stacked: true,
            ticks: {
              fontSize: 13,
              fontWeight: 600,
              backdropColor: 'rgb(100,50,37)',
              beginAtZero: true,
              callback: function (value) {
                value = value.toLocaleString();
                return " " + value + " ";
              }
            }
          }]
        },
        maintainAspectRatio: false,
        responsive: true,
      }
    }
  }
}
</script>
