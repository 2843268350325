
<script>
import {Pie} from 'vue-chartjs';

export default {
  extends: Pie,
  data() {
    return {
      filteredTotal: [],
      randomColors: []
    }
  },
  mounted() {
    this.createParam();
    this.setBackgroundColor();
    this.draw();
    this.renderChart(this.pieChartData, this.pieChartOptions);
  },
  computed: {
    order() {
      return this.$store.getters["reports/storesList"]
    }
  },
  watch: {
    order: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.createParam();
        this.setBackgroundColor();
        this.draw();
        this.renderChart(this.pieChartData, this.pieChartOptions);
      }
    }
  },
  methods: {
    draw() {
      this.pieChartData = {
        labels: this.filteredTotal.map(n => n.name),
        datasets: [
          {
            label: 'Всего',
            data: this.filteredTotal.map(d => d.count),
            backgroundColor: this.randomColors,
            hoverOffset: 15,
            hoverBorderWidth: 5
          }
        ]
      }
      this.pieChartOptions = {
        tooltips: {
          displayColors: true,
          backgroundColor: 'rgb(100,50,0)',
          titleFontColor: 'rgb(255,255,255)',
          bodyFontColor: 'rgb(255,255,255)',
          titleAlign: 'center',
          callbacks: {
            label: function (item,everything) {
              let i = item.index;
              let result = (everything.datasets[0].data[i]).toLocaleString();
              return everything.labels[i] +' : ' + result + ' заказ(ов)';
            }
          }
        },
        cutoutPercentage: 30,
        title: {
          display: true,
          text: 'По количеству заказов'
        },
        legend: {
          display: true,
          position: "top"
        },
        hoverBorderWidth: 20,
        responsive: true,
        maintainAspectRatio: true,
        animation: {
          animateScale: true
        },
      }
    },
    createParam() {
      let percent = 0;
      let totalCount = 0;
      let others = {name: 'другие ( < 1% )', count: 0};
      let filter = {};
      for (let i = 0; i < this.order.length; i++) {
        totalCount = totalCount + this.order[i].count
      }
      percent = totalCount / 100;
      for (let i = 0; i < this.order.length; i++) {
        if (this.order[i].count < percent) {
          others.count = others.count + this.order[i].count
        }
      }
      filter = this.order.filter(m => m.count > percent);
      filter.push(others);
      this.filteredTotal = filter;
      return this.filteredTotal;

    },
    setBackgroundColor() {
      for (let q = 0; q < this.filteredTotal.length; q++) {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        this.randomColors.push(color);
      }
      return this.randomColors;
    }
  }
}
</script>
